<template>
  <div>
    <SpezialabonnentenTagesgeldIndex />
  </div>
</template>

<script>
import SpezialabonnentenTagesgeldIndex from '../../../wettbewerbsvergleich/src/components/Spezialabonnenten/SpezialabonnentenTagesgeld/SpezialabonnentenTagesgeldIndex';
export default {
  name: 'SpezialabonnentenTagesgeld',
  components: { SpezialabonnentenTagesgeldIndex },
};
</script>

<style lang="css" scoped></style>
