<template>
  <div class="form-content">
    <v-container class="tagesgeld-nibc-form">
      <v-row>
        <v-col>
          <h2 class="form-headline-content">NIBC-Auswertung: Tagesgeld</h2>
        </v-col>
        <v-col>
          <DownloadFile
            location="tagesgeld"
            :param="formParamTagesgeld"
            v-if="dataTable.tagesgeld.rows.length"
          />
        </v-col>
      </v-row>
      <v-form>
        <v-row class="justify-center">
          <v-col lg="3" sm="5"
            ><v-card-text class="pl-0 font"> Anlagedauer:</v-card-text>
            <v-card-text>6 Monate</v-card-text>
          </v-col>
          <v-col lg="3" sm="5">
            <v-card-text class="pl-0 font">Anlagebetrag</v-card-text>
            <v-select
              :color="color"
              item-color="selected"
              :attach="true"
              background-color="white"
              v-model.number="anlagebetrag"
              @change="handleChangeValue"
              item-text="text"
              item-value="value"
              solo
              dense
              :items="[
                { text: 5000, value: 5000 },
                { text: 50000, value: 50000 },
              ]"
            >
              <template #selection="{ item }">
                <span>{{ item.text | anlagebetrag(0) }}</span>
              </template>
              <template v-slot:item="{ item }"
                ><span>{{ item.text | anlagebetrag(0) }}</span></template
              >
            </v-select>
          </v-col>
          <v-col lg="3" sm="5">
            <v-card-text class="pl-0 font"> Datum </v-card-text>
            <spezialabonennten-date-picker
              @changed="handleChangeDate"
              :dates="data.datePicker.dates"
              :originalArr="data.datePicker.originalArr"
              storePath="formParamTagesgeld.zeitraum"
              storeNamespace="specialSubscribers"
            />
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-spacer></v-spacer>
          <v-col sm="5" lg="4">
            <v-btn
              class="pl-0 action-btn"
              :color="color"
              plain
              @click="resetForm"
              >zurücksetzen</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import SpezialabonenntenDatePicker from '../SpezialabonnentenDatePicker/SpezialabonnentenDatePicker';
import DownloadFile from '../DownloadFile/DownloadFile';
import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'SpezialabonnentenTagesgeldForm',
  components: { SpezialabonenntenDatePicker, DownloadFile },
  data() {
    return {
      color: BASE_BLACK_COLOR,
    };
  },
  computed: {
    ...mapGetters({
      formParamTagesgeld: 'specialSubscribers/getFormParamTagesgeld',
      data: 'specialSubscribers/getFormParamTagesgeld',
      isAuthorized: 'auth/isAuthorized',
      dataTable: 'specialSubscribers/getDataTable',
    }),
    ...mapFields('specialSubscribers', ['formParamTagesgeld.anlagebetrag']),
    ...mapFields('specialSubscribers', ['formParamTagesgeld.zeitraum']),
  },
  methods: {
    ...mapMutations({
      resetFormTagesgeld: 'specialSubscribers/resetFormTagesgeld',
      setBerechnung: 'specialSubscribers/setBerechnung',
      setDataForm: 'specialSubscribers/setDataForm',
      error: 'specialSubscribers/error',
      loading: 'specialSubscribers/loading',
      resetTable: 'specialSubscribers/resetTable',
    }),
    ...mapActions({
      sendForm: 'specialSubscribers/sendForm',
      fetchDataForForm: 'specialSubscribers/fetchDataForForm',
    }),
    async handleChangeValue() {
      await this.resetTable('tagesgeld');
      return this.fetchData();
    },
    async handleChangeDate(val) {
      const payload = Object.assign(val, { productType: 'tagesgeld' });
      await this.resetTable('tagesgeld');
      await this.setBerechnung(payload);
      return this.fetchData();
    },
    async fetchData() {
      await this.fetchDataForForm(this.data)
        .then((res) => {
          const data = Object.assign(res.data, { productType: 'tagesgeld' });
          this.setDataForm(data);
          this.error({ tagesgeld: false });
        })
        .catch((e) => {
          if (e.response.status !== 401) {
            this.loading(false);
            this.error({ tagesgeld: true });
            console.log(e);
          }
        });
      await this.sendForm(this.data);
      this.loading(false);
    },
    async resetForm() {
      await this.resetFormTagesgeld();
      await this.resetTable('tagesgeld');
      await this.fetchData();
    },
  },
  watch: {
    isAuthorized(val) {
      if (val && !Object.values(this.dataTable.tagesgeld).flat().length) {
        this.fetchData();
      }
    },
  },
  created() {
    if (
      this.isAuthorized &&
      !Object.values(this.dataTable.tagesgeld).flat().length
    ) {
      this.fetchData();
    }
  },
};
</script>

<style lang="scss" scoped>
.tagesgeld-nibc-form {
  padding-top: 25px;
  & > div > div > h2 {
    padding-bottom: 30px !important;
  }
}
.my-primary {
  color: $white;
}
</style>
