<template>
  <div>
    <Loader v-if="loading" />
    <div class="form" :style="defaultStyle">
      <SpezialabonnentenTagesgeldForm />
    </div>
    <div class="box__error">
      <v-alert
        v-if="error.tagesgeld"
        border="right"
        colored-border
        type="error"
        elevation="1"
        >Nicht gefunden</v-alert
      >
    </div>
    <v-container>
      <SpezialabonnentenTable
        anbieter
        zinssatz
        rendite
        v-if="dataTable.tagesgeld.rows.length"
        :rows="dataTable.tagesgeld.rows"
        :headers="dataTable.tagesgeld.headers"
      />
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SpezialabonnentenTable from '../SpezialabonnentenTable/SpezialabonnentenTable';
import SpezialabonnentenTagesgeldForm from './SpezialabonnentenTagesgeldForm';
import Loader from '../../common/Loader/Loader';

export default {
  name: 'SpezialabonnentenTagesgeldIndex',
  components: {
    SpezialabonnentenTable,
    SpezialabonnentenTagesgeldForm,
    Loader,
  },
  computed: {
    ...mapGetters({
      dataTable: 'specialSubscribers/getDataTable',
      formParamTagesgeld: 'specialSubscribers/getFormParamTagesgeld',
      loading: 'specialSubscribers/getLoading',
      error: 'specialSubscribers/getError',
    }),
    defaultStyle() {
      const height = window.innerHeight - 335;
      return (
        !this.dataTable.tagesgeld.rows.length && {
          'min-height': `${height}px`,
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 950px) {
  .form {
    margin-top: 28px;
  }
}
</style>
